import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import { Switch } from "antd";
import Header from "./Header";
import FeaturesCards from "./FeaturesCards";
import FeatureItem from "./FeatureItem";
import NotAccessibleIcon from "./NotAccessibleIcon";
import Footer from "./Footer";
import "./Landing.css";
import "./Header.css";
import image from "../../assets/image.png";
import DashboardImg from "../../assets/DashboardLight.png";
import { useNavigate } from "react-router-dom";

const landing = {
  basicPackage: [
    { id: 1, name: "الى 200 حجز شهري" },
    { id: 2, name: "صفحة حجز خاصة لأعمالك" },
    { id: 3, name: "تذكير بالبريد الالكتروني" },
    { id: 4, name: "تعديل التوافر" },
    { id: 5, name: "دعم متاح على مدار الساعة" },
  ],
  standardFeatures: [
    { id: 1, name: "عدد لا محدود للحجوزات" },
    { id: 2, name: "صفحة حجز خاصة لأعمالك" },
    { id: 3, name: "تذكير بالبريد الالكتدوني" },
    { id: 4, name: "تعديل التوافر بأكثر من طريقة" },
    { id: 5, name: "دعم متاح على مدار الساعة" },
    { id: 6, name: "قبول الدفع " },
    { id: 7, name: "تذكير عن طريق الرسائل النصية" },
  ],
  corporatePackage: [
    { id: 1, name: "عدد لا محدود للحجوزات" },
    { id: 2, name: "صفحة حجز بتصميم خاصة بشكل مختلف " },
    { id: 3, name: "تذكير عن طريق البريد الالكتروني والرسائل النصية " },
    { id: 4, name: "تعديل التوافر بطريق مختلفة" },
    { id: 5, name: "اولوية دعم متاحة على مدار الساعة" },
    { id: 6, name: "قبول مختلف طرق الدفع" },
    { id: 7, name: "مميزات خاصة بالباقة القياسية" },
  ],
};

const monthlyPrices = {
  basic: 90,
  standard: 190,
  corporate: "مخصصة",
};

const annualPrices = {
  basic: 990,
  standard: 1990,
  corporate: "مخصصة",
};

function Landing() {
  const { t } = useTranslation();
  const [isAnnual, setIsAnnual] = useState(false);
  const navigate = useNavigate();

  const togglePricing = () => {
    setIsAnnual(!isAnnual);
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleWhatsAppRedirect = () => {
    window.location.href = "https://wa.me/966559272312";
  };
  const prices = isAnnual ? annualPrices : monthlyPrices;

  return (
    <div className="background-img">
      <Header />
      <section className="info-part">
        <div className="info-container">
          <h1>{t("landing.title")}</h1>
          <p>{t("landing.description")}</p>
          <div className="info-btns">
            <button
              className="white-btn"
              onClick={() => handleNavigate("/register")}
            >
              {t("landing.startFreeTrial")}
            </button>
            <Link
              to="features"
              smooth={true}
              duration={500}
              className="blue-btn"
            >
              {t("landing.features")}
            </Link>
          </div>
          <img src={image} alt="image" className="info-img" />
        </div>
      </section>
      <div>
        <section id="features" className="sections" style={{ padding: "20px" }}>
          <h2>
            <span className="features-text">{t("landing.booklyFeatures")}</span>
          </h2>
          <p className="features-p">{t("landing.booklySystem")}</p>
          <FeaturesCards />
        </section>
        <section className="payment-img-container">
          <div className="payment-container">
            <img
              src={DashboardImg}
              alt="Dashboard-Img"
              className="dashboard-img"
            />
            <div className="payment-text">
              <h1>{t("landing.easyPayment")}</h1>
              <p>{t("landing.paymentDescription")}</p>
              <button
                style={{ cursor: "pointer" }}
                onClick={() => handleNavigate("/register")}
              >
                {t("landing.tryNow")}
              </button>
            </div>
          </div>
        </section>
        <section id="pricing" className="sections" style={{ padding: "20px" }}>
          <div className="pricing">
            <h1>{t("landing.pricing")}</h1>
            <p>{t("landing.pricingDescription")}</p>
            <div className="toggle-container">
              <span>{t("landing.Monthly Pricing")}</span>
              <Switch
                onChange={togglePricing}
                defaultChecked={false}
                style={{
                  backgroundColor: isAnnual ? "#152C5B" : "#F2F2FF",
                  width: "50px",
                }}
              />
              <span>{t("landing.Annual Pricing")}</span>
            </div>
            <div className="pricing-card-container">
              <div className="pricing-card-white">
                <h1>{t("landing.basicPackage")}</h1>
                <p>
                  {t("landing.basicPackageDescription")}
                  <br />
                  {t("landing.basicPackageDescription2")}
                </p>
                <h2 style={{ direction: "rtl" }}>
                  {isAnnual ? (
                    <span>
                      {prices.basic}
                      &nbsp;
                      {t("landing.SR")}
                    </span>
                  ) : (
                    <>
                      <span
                        style={{
                          textDecoration: "line-through",
                          color: "red",
                        }}
                      >
                        {prices.basic}
                        &nbsp;
                        {t("landing.SR")}
                      </span>
                    </>
                  )}
                </h2>
                {isAnnual ? (
                  <button onClick={() => handleNavigate("/register")}>
                    {t("landing.selectPackage")}
                  </button>
                ) : (
                  <button onClick={() => handleNavigate("/register")}>
                    {t("landing.selectPackageFree")}
                  </button>
                )}
                <div style={{ marginTop: "48" }}>
                  {landing.basicPackage.map((feature) => (
                    <FeatureItem
                      key={feature.id}
                      feature={feature}
                      theme="light"
                    />
                  ))}
                </div>
                <div className="not-acc-item">
                  <span className="not-acc-text">
                    {t("landing.paymentNotAvailable")}
                  </span>
                  <NotAccessibleIcon className="feature-item-icon" />
                </div>
                <div className="not-acc-item">
                  <span className="not-acc-text">
                    {t("landing.smsReminderNotAvailable")}
                  </span>
                  <NotAccessibleIcon className="feature-item-icon" />
                </div>
              </div>
              <div className="pricing-card-blue">
                <h1>{t("landing.standardPackage")}</h1>
                <p>{t("landing.standardPackageDescription")}</p>
                <h2 style={{ direction: "rtl" }}>
                  {isAnnual ? (
                    <span>
                      {prices.standard}
                      &nbsp;
                      {t("landing.SR")}
                    </span>
                  ) : (
                    <>
                      <span
                        style={{
                          textDecoration: "line-through",
                          color: "red",
                        }}
                      >
                        {prices.standard}
                        &nbsp;
                        {t("landing.SR")}
                      </span>
                    </>
                  )}
                </h2>
                {isAnnual ? (
                  <button onClick={() => handleNavigate("/register")}>
                    {t("landing.selectPackage")}
                  </button>
                ) : (
                  <button onClick={() => handleNavigate("/register")}>
                    {t("landing.selectPackageFree")}
                  </button>
                )}
                <div style={{ marginTop: "48" }}>
                  {landing.standardFeatures.map((feature) => (
                    <FeatureItem
                      key={feature.id}
                      feature={feature}
                      theme="dark"
                    />
                  ))}
                </div>
              </div>
              <div className="pricing-card-white">
                <h1>{t("landing.corporatePackage")}</h1>
                <p>{t("landing.corporatePackageDescription")}</p>
                <h2>{prices.corporate}</h2>
                <button onClick={handleWhatsAppRedirect}>
                  {t("landing.contactSales")}
                </button>
                <div style={{ marginTop: "48" }}>
                  {landing.corporatePackage.map((feature) => (
                    <FeatureItem
                      key={feature.id}
                      feature={feature}
                      theme="light"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default Landing;
