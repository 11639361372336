import React from "react";

// Helper function to convert numbers to Arabic words
const numberToArabicWords = (num) => {
  const arabicNumbers = [
    "صفر",
    "واحد",
    "اثنان",
    "ثلاثة",
    "أربعة",
    "خمسة",
    "ستة",
    "سبعة",
    "ثمانية",
    "تسعة",
  ];
  const tens = [
    "",
    "عشر",
    "عشرون",
    "ثلاثون",
    "أربعون",
    "خمسون",
    "ستون",
    "سبعون",
    "ثمانون",
    "تسعون",
  ];
  const hundreds = [
    "",
    "مائة",
    "مائتان",
    "ثلاثمائة",
    "أربعمائة",
    "خمسمائة",
    "ستمائة",
    "سبعمائة",
    "ثمانمائة",
    "تسعمائة",
  ];

  if (num < 10) {
    return arabicNumbers[num];
  } else if (num < 100) {
    const unit = num % 10;
    const ten = Math.floor(num / 10);
    return `${unit > 0 ? arabicNumbers[unit] + " و" : ""}${tens[ten]}`;
  } else {
    const remainder = num % 100;
    const hundred = Math.floor(num / 100);
    return `${hundreds[hundred]}${
      remainder > 0 ? " و" + numberToArabicWords(remainder) : ""
    }`;
  }
};

const Service = ({ service }) => (
  <div
    key={service.id}
    style={{
      direction: "rtl",
      fontSize: "16px",
      padding: "10px",
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "40%",
        }}
      >
        <div
          style={{
            background: `rgba(${service.color} , 0.33 )`,
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            marginLeft: "10px",
          }}
        ></div>
        <div>{service.name}</div>
      </div>
      <div style={{ width: "20%", textAlign: "center" }}>
        {`${service.price} ريال`}
      </div>
      <div style={{ width: "30%", textAlign: "center" }}>
        {`${numberToArabicWords(service.duration)} دقيقة`}
      </div>
    </div>
  </div>
);

export default Service;
