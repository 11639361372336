import React, { useState } from "react";
import landingIcon from "../../assets/landingIcon.svg";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";

import "./Header.css";

function Header() {
  const [navActive, setNavActive] = useState(false);
  const navigate = useNavigate();

  const toggleNav = () => {
    setNavActive(!navActive);
  };

  const closeNav = () => {
    setNavActive(false);
  };

  const handleNavigate = (path) => {
    navigate(path);
    closeNav();
  };

  const handleWhatsAppRedirect = () => {
    window.location.href = "https://wa.me/966559272312";
    closeNav();
  };

  return (
    <div className="container">
      <div className={`nav-bar ${navActive ? "active" : ""}`}>
        <div className="nav-bar-content">
          <button
            onClick={() => handleNavigate("/register")}
            className="white-btn"
          >
            إنشاء حساب
          </button>
          <button onClick={() => handleNavigate("/login")} className="blue-btn">
            دخول
          </button>
        </div>
        <div className="nav-bar-content">
          <Link to="features" smooth={true} duration={500} onClick={closeNav}>
            المميزات
          </Link>
          <Link to="pricing" smooth={true} duration={500} onClick={closeNav}>
            الاسعار
          </Link>
          <a onClick={handleWhatsAppRedirect}>تواصل معنا</a>
        </div>
        <div className="nav-bar-mobile">
          <Link onClick={() => handleNavigate("/register")}>إنشاء حساب</Link>
          <Link onClick={() => handleNavigate("/login")}>دخول</Link>

          <Link to="features" smooth={true} duration={500} onClick={closeNav}>
            المميزات
          </Link>
          <Link to="pricing" smooth={true} duration={500} onClick={closeNav}>
            الاسعار
          </Link>
          <a onClick={handleWhatsAppRedirect}>تواصل معنا</a>
        </div>
      </div>

      <div style={{ direction: "rtl" }} onClick={toggleNav}>
        <img src={landingIcon} alt="icon" className="icon-header" />
        <span className="menu-icon">{navActive ? "✖" : "☰"}</span>
      </div>
    </div>
  );
}

export default Header;
