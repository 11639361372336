import React, { useState } from "react";
import { Form, Input, Button, Row, Col, notification } from "antd";
import {
  UserOutlined,
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { register, getExists } from "../../services/api";
import icon from "../../assets/icon.svg";
import OTPForm from "./OTPForm";
import "./Register.css";

const RegisterForm = ({ onRegisterSuccess }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [email, setEmail] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [availabilityMessage, setAvailabilityMessage] = useState("");

  const validateTenantID = async (_, value) => {
    if (!value) {
      setAvailabilityMessage("");
      return Promise.reject();
    }
    try {
      const response = await getExists(value);
      if (response.exists) {
        setAvailabilityMessage("");
        return Promise.reject(
          new Error(`${value}.trybookly.net ${t("register.tenantIdTaken")}`)
        );
      } else {
        setAvailabilityMessage(
          `${value}.trybookly.net ${t("register.tenantIdAvailable")}`
        );
        return Promise.resolve();
      }
    } catch (error) {
      setAvailabilityMessage("");
      return Promise.reject(new Error(t("register.tenantCheckError")));
    }
  };

  const onTenantIDChange = (e) => {
    const { value } = e.target;
    form.setFieldsValue({ tenantID: value });
    if (value === "") {
      setAvailabilityMessage("");
    }
  };

  const onPhoneChange = (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, "");
    if (numericValue.length <= 9) {
      form.setFieldsValue({ phone: numericValue });
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    setTenantId(values.tenantID);
    const requestData = { ...values, tenantId: values.tenantID };
    try {
      const response = await register(requestData);
      setEmail(values.email);
      setShowOtpForm(true);

      if (onRegisterSuccess) {
        onRegisterSuccess(response.token);
      }
    } catch (error) {
      notification.error({
        message: t("register.registrationFailed"),
        description: error.message,
      });
      console.error(t("register.registrationFailed"), error.message);
    } finally {
      setLoading(false);
    }
  };

  const validatePassword = (_, value) => {
    if (!value) {
      return Promise.reject(new Error(t("register.enterPassword")));
    }

    const passwordErrors = [];
    if (value.length < 6) {
      passwordErrors.push(t("register.passwordTooShort"));
    }
    if (!/[A-Z]/.test(value)) {
      passwordErrors.push(t("register.passwordRequiresUpper"));
    }
    if (!/[a-z]/.test(value)) {
      passwordErrors.push(t("register.passwordRequiresLower"));
    }
    if (!/[0-9]/.test(value)) {
      passwordErrors.push(t("register.passwordRequiresDigit"));
    }
    if (!/[^a-zA-Z0-9]/.test(value)) {
      passwordErrors.push(t("register.passwordRequiresNonAlphanumeric"));
    }

    if (passwordErrors.length > 0) {
      return Promise.reject(
        <div>
          {passwordErrors.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      );
    }

    return Promise.resolve();
  };

  return (
    <Row justify="center" align="middle">
      <Col>
        <div className="register-form-container">
          <div className="register-form">
            <img src={icon} alt="icon" className="icon" />
            <h1>{t("register.title")}</h1>
            {!showOtpForm ? (
              <Form
                form={form}
                name="registerForm"
                onFinish={onFinish}
                size="large"
              >
                <Form.Item
                  name="firstName"
                  rules={[
                    { required: true, message: t("register.enterFirstName") },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder={t("register.firstName")}
                  />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  rules={[
                    { required: true, message: t("register.enterLastName") },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder={t("register.lastName")}
                  />
                </Form.Item>
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: t("register.enterProjectName") },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder={t("register.projectName")}
                  />
                </Form.Item>
                <Form.Item
                  name="tenantID"
                  rules={[
                    { required: true, message: t("register.entertenantID") },
                    { validator: validateTenantID },
                    {
                      pattern: /^[A-Za-z]+$/,
                      message: t("register.onlyEnglishAlphabet"),
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder={t("register.tenantID")}
                    onChange={onTenantIDChange}
                  />
                </Form.Item>
                {availabilityMessage && (
                  <div
                    style={{
                      color: availabilityMessage.includes(
                        t("register.tenantIdTaken")
                      )
                        ? "red"
                        : "green",
                      marginTop: "-7px",
                    }}
                  >
                    {availabilityMessage}
                  </div>
                )}
                <Form.Item
                  name="phone"
                  rules={[
                    { required: true, message: t("register.enterPhoneNumber") },
                  ]}
                >
                  <Input
                    prefix={<PhoneOutlined className="site-form-item-icon" />}
                    placeholder={t("register.phone")}
                    maxLength={10}
                    onChange={onPhoneChange}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: t("register.enterEmail") },
                    {
                      type: "email",
                      message: t("register.invalidEmailFormat"),
                    },
                  ]}
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder={t("register.email")}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ validator: validatePassword }]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder={t("register.password")}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    block
                  >
                    {t("register.registerButton")}
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              <OTPForm email={email} tenantId={tenantId} />
            )}
            <div className="separator">
              <span className="separator-text">{t("register.or")}</span>
            </div>
            <div className="create-account">
              <span>
                {t("register.haveAccount")}
                <a href="/login" style={{ margin: "3px" }}>
                  {t("register.logIn")}
                </a>
              </span>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default RegisterForm;
