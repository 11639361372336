import axios from "axios";
import { jwtDecode } from "jwt-decode";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

const withAuthHeaders = (jwtToken, additionalHeaders = {}) => ({
  headers: {
    accept: "*/*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${jwtToken}`,
    ...additionalHeaders,
  },
});

const handleRequest = async (
  method,
  url,
  data,
  jwtToken,
  additionalHeaders = {}
) => {
  try {
    const response = await api({
      method,
      url,
      data,
      ...withAuthHeaders(jwtToken, additionalHeaders),
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

export const login = async (formData) => {
  try {
    const response = await api.post("/Auth/login", formData, {
      headers: {
        "X-Tenant-ID": formData.email,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

export const register = async (data) => {
  console.log("register------tenantId---->", data);
  try {
    const response = await api.post("/Tenant", data, {
      headers: {
        "X-Tenant-ID": data.tenantID,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

export const addCategory = async (categoryData, jwtToken) => {
  return handleRequest("post", "/Categories", categoryData, jwtToken);
};

export const getCategories = async (jwtToken) => {
  return handleRequest("get", "/Categories", null, jwtToken);
};

export const addService = async (serviceData, jwtToken) => {
  return handleRequest("post", "/Services", serviceData, jwtToken);
};

export const getServices = async (jwtToken) => {
  return handleRequest("get", "/Services", null, jwtToken);
};

export const getMonthlyAvailabilities = async (data, jwtToken) => {
  return handleRequest(
    "get",
    `/Availabilities/monthly/${data.year}/${data.month}`,
    null,
    jwtToken
  );
};

export const getAvailabilities = async (jwtToken) => {
  try {
    const response = await api.get(
      "/Availabilities",
      withAuthHeaders(jwtToken)
    );
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

export const addAvailabilitySpecificDay = async (
  availabilityData,
  jwtToken
) => {
  console.log("availabilityData ----- > ", availabilityData);
  return handleRequest(
    "post",
    "/Availabilities/specific-day",
    availabilityData,
    jwtToken
  );
};
export const addAvailabilityWeekly = async (availabilityData, jwtToken) => {
  console.log("addAvailabilityWeekly --------->", availabilityData);
  return handleRequest(
    "post",
    "/Availabilities/weekly",
    availabilityData,
    jwtToken
  );
};
export const getAvailabilityWeekly = async (jwtToken) => {
  return handleRequest("get", "/Availabilities/weekly", null, jwtToken);
};

export const addPeriodicAvailability = async (availabilityData, jwtToken) => {
  return handleRequest(
    "post",
    "/Availabilities/period",
    availabilityData,
    jwtToken
  );
};

export const editAvailability = async (
  availabilityId,
  availabilityData,
  jwtToken
) => {
  console.log("availabilities", availabilityId);
  return handleRequest(
    "put",
    `/Availabilities/${availabilityId}`,
    availabilityData,
    jwtToken
  );
};

export const deleteAvailability = async (date, jwtToken) => {
  console.log("deleteAvailability", date);

  return handleRequest(
    "delete",
    `/Availabilities/specific-day/${date}`,
    null,
    jwtToken
  );
};

export const verifyOtp = async (data) => {
  try {
    const response = await api.post("/Tenant/confirm-registration", data, {
      headers: {
        "X-Tenant-ID": data.tenantId,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

export const addUser = async (data, jwtToken) => {
  return handleRequest("post", "/Users", data, jwtToken);
};
export const getUsers = async (data, jwtToken) => {
  return handleRequest(
    "get",
    `/Users?PageNumber=${data.PageNumber}&PageSize=${data.PageSize}&searchName=${data.searchName}`,
    null,
    jwtToken
  );
};

export const getBookings = async (jwtToken) => {
  return handleRequest("get", "/Bookings", null, jwtToken);
};

export const createBooking = async (data) => {
  const tenantId = getTenantID();
  try {
    const response = await api.post("/Bookings", data, {
      headers: {
        "X-Tenant-ID": tenantId,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

export const getAvailableSlots = async (data, tenantID) => {
  console.log("Data and Tenant ID:", data, tenantID);
  const url = `/Bookings/available-slots?serviceId=${data.serviceId}&startDate=${data.startDate}&endDate=${data.endDate}`;
  try {
    const response = await api.get(url, {
      headers: {
        "X-Tenant-ID": tenantID,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

export const getExists = async (value) => {
  try {
    const response = await api.get(`/Tenant/exists/${value}`, {
      headers: {
        "X-Tenant-ID": value,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};
// Function to retrieve Tenant ID from JWT
const getTenantID = () => {
  const jwtToken = localStorage.getItem("token");
  return jwtToken ? jwtDecode(jwtToken).TenantId : "";
};
