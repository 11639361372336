import React, { useState, useEffect } from "react";
import { Table, Modal, Input, Button } from "antd";
import { useTranslation } from "react-i18next";
import ClientAppointments from "./ClientAppointments";
import AddClient from "./AddClient";
import { getUsers, getBookings } from "../../../services/api";

function Clients() {
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [clientAppointments1, setClientAppointments] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isAddClientVisible, setIsAddClientVisible] = useState(false);
  const { t } = useTranslation();
  const jwtToken = localStorage.getItem("token");

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const jwtToken = localStorage.getItem("token");
        const data = {
          searchName: "",
          PageNumber: 1,
          PageSize: 50,
        };

        const response = await getUsers(data, jwtToken);
        setClients(response.data);
        setFilteredClients(response.data);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    const fetchAppointmentsData = async () => {
      try {
        const response = await getBookings(jwtToken);
        setAppointments(response);
      } catch (error) {
        console.error("Error fetching appointments:", error);
      }
    };

    fetchClients();
    fetchAppointmentsData();
  }, []);

  const refreshClients = async () => {
    try {
      const jwtToken = localStorage.getItem("token");
      // TODO : use data
      const data = {
        searchName: "",
        PageNumber: 1,
        PageSize: 50,
      };

      const response = await getUsers(data, jwtToken);
      setClients(response.data);
      setFilteredClients(response.data);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const showClientAppointments = (client) => {
    setSelectedClient(client);
    const clientAppointments = appointments.filter(
      (appointment) => appointment.user.phoneNumber === client.phoneNumber
    );
    setClientAppointments(clientAppointments);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setSelectedClient(null);
    setClientAppointments([]);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedClient(null);
    setClientAppointments([]);
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
    const filtered = clients.filter(
      (client) =>
        client.firstName.toLowerCase().includes(value.toLowerCase()) ||
        client.lastName.toLowerCase().includes(value.toLowerCase()) ||
        client.email.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredClients(filtered);
  };

  const columns = [
    {
      title: t("FirstName"),
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: t("LastName"),
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: t("Phone"),
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: t("Email"),
      dataIndex: "email",
      key: "email",
    },
  ];

  const handleAddClientOk = async () => {
    setIsAddClientVisible(false);
    await refreshClients(); // Refresh clients list after adding a client
  };

  const handleAddClientCancel = () => {
    setIsAddClientVisible(false);
  };

  return (
    <div style={{ margin: "20px" }}>
      <h2>{t("Clients")}</h2>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 15px",
        }}
      >
        <Input.Search
          placeholder={t("Search by name or email") + " ... "}
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: 300 }}
        />
        <Button
          className="copy-btn"
          onClick={() => setIsAddClientVisible(true)}
        >
          {t("Add Client")}
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={filteredClients}
        rowKey="id"
        pagination={false} // Disable pagination
        onRow={(record) => ({
          onClick: () => showClientAppointments(record),
        })}
      />

      {selectedClient && (
        <Modal
          title={`${t("Appointments for")} ${selectedClient.firstName} ${
            selectedClient.lastName
          }`}
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          style={{ direction: "rtl" }}
          footer={[
            <Button key="ok" type="primary" onClick={handleOk}>
              {t("OK")}
            </Button>,
          ]}
        >
          <ClientAppointments appointments={clientAppointments1} />
        </Modal>
      )}

      <AddClient
        isVisible={isAddClientVisible}
        handleOk={handleAddClientOk}
        handleCancel={handleAddClientCancel}
      />
    </div>
  );
}

export default Clients;
